import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import {
  Grid,
  Typography,
  InputLabel,
  Input,
  Button,
  TextField,
  FormControl,
} from "@mui/material";
const About = () => {
  const form: any = useRef();

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ern13kf",
        "template_8p9uv1i",
        form.current,
        "user_ZUCOcVfnk0c1lgIisruKa"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Typography variant='h5' sx={{ textAlign: "center", color: "white" }}>
            Save the Date: <br /> August 6th, 2022
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Typography variant='h5' sx={{ textAlign: "center", color: "white" }}>
            RSVP:
          </Typography>
        </Grid>
        <Grid xs={3}></Grid>
        <Grid
          item
          xs={6}
          sx={{ mt: 1 }}
          justifyContent='center'
          alignItems='center'
          alignContent='center'
          className='place-items-center'
        >
          {/* <Typography
            variant='h2'
            sx={{ textAlign: "center", color: "white" }}
          >
            To RSVP, please email{" "}
            <a
              href='mailto:douglaspeterson6@gmail.com'
              target='_blank'
              style={{ color: "white" }}
            >
              Douglaspeterson6@gmail.com
            </a>
          </Typography> */}
          <form
            ref={form}
            onSubmit={sendEmail}
            className='place-items-center w-full'
          >
            <Typography variant='h6' sx={{ color: "white", marginTop: 5 }}>
              Name
            </Typography>
            <input
              className='input input-bordered  w-full'
              name='from_name'
              color='primary'
            />
            <Typography variant='h6' sx={{ color: "white", marginTop: 2 }}>
              Email
            </Typography>
            <input
              type='email'
              name='user_email'
              className='input input-bordered w-full '
            />
            <Typography variant='h6' sx={{ color: "white", marginTop: 2 }}>
              Will you be attending?
            </Typography>

            <input name='message' className='input input-bordered w-full ' />
            <Button
              type='submit'
              variant='contained'
              color='secondary'
              sx={{ mx: 1, marginTop: 5 }}
            >
              Submit
            </Button>
          </form>
        </Grid>
        <Grid xs={3}></Grid>
      </Grid>
    </>
  );
};

export default About;
