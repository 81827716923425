import { Grid, Typography } from "@mui/material";
import { color } from "@mui/system";

const Registry = () => {
  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Typography variant='h4' sx={{ textAlign: "center", color: "white" }}>
            Registry
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Typography
            variant='h5'
            sx={{ textAlign: "center", color: "white", mx: 10 }}
          >
            We’re registered at Target
          </Typography>
          <Typography
            variant='h6'
            sx={{ textAlign: "center", color: "white", mx: 10 }}
          >
            <a
              style={{ color: "white" }}
              href='https://www.target.com/gift-registry/gift/dougandjay'
            >
              Click Here to View Registry
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 5 }}></Grid>
        <Grid item sx={{ mt: 10, pb: 10 }}></Grid>
      </Grid>
    </>
  );
};

export default Registry;
